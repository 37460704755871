import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/privacy'
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue')
  },
  {
    path: '/privacy', // 隐私政策
    name: 'Privacy',
    component: () => import('../views/Privacy.vue')
  },
  {
    path: '/loan/privacy', // 隐私政策
    name: 'LoanPrivacy',
    component: () => import('../views/LoanPrivacy.vue')
  },
  {
    path: '/loans/privacy', // 隐私政策
    name: 'LoansPrivacy',
    component: () => import('../views/LoansPrivacy.vue')
  },
  {
    path: '/protocol', // 用户协议
    name: 'Protocol',
    component: () => import('../views/Protocol.vue')
  },
  {
    path: '/bookkeepingPrivacy', //  隐私政策
    name: 'BookkeepingPrivacy',
    component: () => import('../views/bookkeeping/Privacy.vue')
  },
  {
    path: '/bookkeepingProtocol', // 用户协议
    name: 'BookkeepingProtocol',
    component: () => import('../views/bookkeeping/Protocol.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
