import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vant from './plugins/vant'
import 'normalize.css/normalize.css'

import Vue3Lottie from 'vue3-lottie'
import 'vue3-lottie/dist/style.css'

createApp(App).use(Vue3Lottie).use(store).use(router).use(vant).mount('#app')
